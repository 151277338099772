<template>
  <h1>Oops, {{ resource }} je zoekt bestaat niet meer</h1>
  <router-link class="btn btn-warning" :to="{name:'Home'}">🎅 Ga terug naar de homepagina 🎅 </router-link>
</template>


<script>
export default {
  props:{
    resource:{
      type: String,
      requiered: true,
      default: 'de pagina die',
    }
  }
}
</script>