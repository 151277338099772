<template>
  <h1>Dit is de editpagina</h1>
</template>

<script>

export default {
  props: ["event"],
};
</script>

<style scoped></style>
