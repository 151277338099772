<template>

    <div id="nav">
      <router-link class="mx-2" :to="{ name: 'TicketDetails', params: { id } }">
        Details</router-link
      >
      <router-link  class="mx-2" :to="{ name: 'TicketEdit', params: { id } }">
        Edit</router-link
      >
      <router-link class="mx-2" :to="{ name: 'TicketRegister', params: { id } }">
        Register</router-link
      >
      <router-view :event="event"></router-view>
    </div>
</template>

<script>
import TicketService from "../services/TicketService";

export default {
  name: "TicketDetails",
  data() {
    return {
      event: null,
    };
  },
  props: ["id"],
  created() {
    TicketService.getEvent(this.id)
        .then((response) => {
          this.event = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$router.push({
            name:'404Resource',
            params: {resource: 'het event dat'}
          })
        });
  },
};
</script>

<style scoped></style>
