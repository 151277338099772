import axios from "axios";

const clientApiService  = axios.create({
    baseURL: 'https://my-json-server.typicode.com/loresinnaeve/fakejson',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'content-type': 'application/json',

    }
})
export default{
    getEvents(perPage, page){
        return clientApiService.get('/events?_limit=' + perPage + '&_page=' + page)
    },
    getEvent(id){
        return clientApiService.get('/events/' + id)
    }
}